<template>
  <v-card class="fill-height">
      <v-toolbar color="color1 color1Text--text" v-if="title && !noToolbar">
        <v-toolbar-title>{{title}}</v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline justify-center" v-else-if="title">
        {{title}}
      </v-card-title>
      <v-divider></v-divider>
    <v-card-text>
      <slot></slot>
      <template v-for="(rowGroup, i) in data" >
        <v-row dense v-for="row in rowGroup" :key="`${row.label}-${i}`" :class="i % 2 > 0 ? 'grey lighten-4' : ''">
          <v-col
            v-if="!row.hide"
            class="font-weight-bold py-0 shrink text-no-wrap"
            :class="{indent: row.indent}"
          >
            {{row.label}}<i class="ml-2 fad fa-badge-check success--text clickable" v-if="row.verified" @click.stop="$emit('verify-click')"></i>
          </v-col>
          <v-col v-if="!row.hide" class="text-right py-0 grow d-flex align-center justify-end">
            <v-skeleton-loader type="text" :loading="loading" :width="loading ? '75px': null" transition="scale-transition">
              <div>{{row.value}}</div>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['data', 'loading', 'title', 'noToolbar']
}
</script>
